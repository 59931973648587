.root
  .input
    width: 100%
    // input
    //   border-radius: 4px
    //   border: 1px solid black
    //   width: 100%
    margin: 10px 0 20px 0 !important
  .errorWrapper
    position: relative
    .error
      position: absolute
      bottom: 5px
      font-size: 15px
      line-height: 1
      left: 0
      color: red
