.root
    display: flex
    flex-direction: column
    position: relative
    @media screen and (max-width: 900px)
        box-shadow: none
        padding: 0 12px
    .editBtn
        position: absolute
        top: 0
        right: 0
        border: 1px solid
        border-radius: 4px
        z-index: 9
    .title
        font-size: 26px
        font-weight: 600
        height: 67px
    .button
        display: flex
        justify-content: center
        margin-top: 2.5rem
        .submitButton
            // border-radius: 36px
            font-size: 15px
            background-color: #000
            // padding: 1rem 5rem
            padding-left: 2rem
            padding-right: 2rem
            width: 60%
            text-transform: none
            &:hover
                background: #5a5a5a
            &:disabled
                background: #F6F8FE
            @media screen and (max-width: 900px)
                width: 100%
        .secondaryButton
            border-radius: 36px
            font-size: 15px
            background-color: #F6F8FE
            color: #000
            padding: 1rem 5rem
            width: fit-content
            text-transform: none
            box-shadow: none
            &:hover
                background: #eeeeee
            &:disabled
                background: #F6F8FE
            @media screen and (max-width: 900px)
                width: 100%
    input
        font-size: 1.2rem

.suggestions
    ::-webkit-scrollbar
        width: 7px
        height: 6px
    ::-webkit-scrollbar-thump
        background: #a0a0a0
        border-radius: 16px
