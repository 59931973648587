.root
    .avatarWrapper
        position: relative
        height: fit-content
        width: fit-content
        .avatar
            height: 102px
            width: 102px
            font-size: 1.75rem
            text-transform: uppercase
            cursor: pointer
            border: 7px solid #FFFFFF
            border-radius: 64px
            &:hover
                border-color: #d6d6d6
    .inputAvatar
        display: none
    .avatarWrapper:hover .cameraLabel
        display: flex
    .cameraLabel
        position: absolute
        z-index: 99
        cursor: pointer
        display: flex
        align-items: center
        justify-content: center
        right: 2%
        bottom: 2%
        border-radius: 50%
        background: #838383
        padding: 5px
        color: #fff
        opacity: 0.4
        &:hover
            opacity: 1
            background: #3e3e3e
    .removeImg
        position: absolute
        inset: 0
        border-radius: 50%
        background: rgba(255, 255, 255, 0.6)
        justify-content: center
        align-items: center
        opacity: 0
        z-index: -2
        animation: fadeOut 1s linear forwards
        &.enable
            zIndex: 50
            display: flex
            animation: fadeIn 0.2s linear forwards
        & .icon-wrapper
            padding: 5px
            cursor: pointer
        & .icon
            font-size: 25px
    @keyframes fadeIn
        opacity: '0'
        opacity: '1'

    @keyframes fadeOut
        opacity: '1'
        opacity: '0'
