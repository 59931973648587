.buttonBack
  font-size: 16px
  display: flex
  align-items: center
  color: #1E3240
  cursor: pointer
  background: transparent
  border: none
  &:hover
    text-decoration: underline
