.root
  padding-top: 15px
  display: flex
  // border-bottom: 1px solid black
  &:last-child
    border-bottom: none
  .imageContainer
    width: 100px
    height: 100px
    border: 1px solid black
    margin-right: 15px
    margin-bottom: 15px
    border-radius: 4px
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center
    position: relative
    &:hover
      .download
        opacity: 1
    .image
      width: 100%
      height: 100%
      background-size: cover
      background-position: center
    i
      font-size: 30px
    .download
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: rgba(0,0,0,0.5)
      text-align: center
      opacity: 0
      transition: opacity 200ms
      cursor: pointer
      i
        color: white
        line-height: 100px
  // .info
  //   .field
  //     margin-bottom: 15px
