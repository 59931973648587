.root
  min-height: 100vh
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  display: flex
  flex-direction: column
  .text
    font-weight: normal
    font-size: 18px
    line-height: 120%
    color: #000000
    margin-bottom: 46px
    text-align: center
    font-weight: bold
  .container
    margin-top: 50px
    text-align: center
  .qr
    display: block
    margin-left: auto
    margin-right: auto
    width: 40%
  .form
    margin-top: 100px
    width: 300px
    display: flex
    justify-content: center
    align-items: center
  .input
    width: 100%
  .submit
    width: 100%
  .noGutter
    margin-right: 0
    margin-left: 0
