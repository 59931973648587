.status
  font-weight: 600
  font-size: 10px
  color: #cba836
  display: flex
  align-items: center
  justify-content: center
  width: max-content
  padding: 5px 12px
  background: #ffeba4
  border-radius: 8px
  &.small
    padding: 0px 12px
  &.denied
    background: #FDD4D4
    color: #EE575E
  &.granted
    background: #D2FBF0
    color: #2CCEA5
