
.contentRight
  display: flex
  height: 100vh
  overflow-x: hidden
  position: relative
.leftContent
  position: relative
  flex: 1
  .background
    width: 100%
    height: 100%
  .logo
    position: absolute
    top: 40px
    left: 45px
  .logoBackground
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    filter: drop-shadow(4px 8px 44px rgba(0, 0, 0, 0.15))
.rightContent
  width: 40%
@media screen and (max-width: 899px)
  .leftContent
    display: none
  .rightContent
    width: 100%
