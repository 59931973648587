@import "./font.sass"

// body > #root > div:not(.layout-wrapper) *
//   font-family: 'Mulish', Arial, sans-serif
//   font-size: 16px
//   color: #1E3240

.spinner-border
  display: flex
  justify-content: center
  align-items: center
  border: none

a
  text-decoration: none

.pagination
  .page-item
    .page-link
      color: #212529
      box-shadow: none
    &.active
      .page-link
        color: #fff
        background: #212529
        border-color: #212529

.dropdown-menu
  .dropdown-item
    &:active
      background: #212529

.modal-width-full .modal-dialog
  max-width: 100%
  @media screen and (min-width: 576px)
    max-width: 80%

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item
  font-size: 12px

.MuiPhoneNumber-flagButton
  width: 30px

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled
  display: none
