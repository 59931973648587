.content
    border-radius: 30px
    background: #F5F5F5
    padding: 10px 20px
    height: fit-content
.timeButton
    border-radius: 36px !important
    border-color: #8F4ED3 !important
    color: #8F4ED3 !important
    width: fit-content
    height: 30px
