
.projectCard
    position: relative
    cursor: pointer
    height: 100%
    width: 100%
    .groupBtn
        position: relative
        padding: 1rem
        padding-bottom: 0
    .backgroundImage
        img
            aspect-ratio: 9 / 2
    .backgroundImageProjectDetail
        img
            aspect-ratio: 9 / 2
    .backgroundImage, .backgroundImageProjectDetail
        position: relative
        max-height: 100%
        max-width: 100%
        height: inherit
        z-index: 0
        background: linear-gradient(160.49deg, rgba(191, 102, 230, 0.5) 13.08%, rgba(5, 190, 239, 0.5) 86.92%)
        img
            @media screen and (max-width: 600px)
                aspect-ratio: 1 / 1
    .wrapper
        min-height: inherit
        position: absolute
        inset: 0
        z-index: 1
        height: 100%
        .top
            padding: 1rem
            text-align: left
        .desc
            padding: 10px
            background: #C1C0F0
            text-align: center
            hyphens: auto
            p, span
                // color: #000
                font-size: 1.15rem
                line-height: 24px
            .name
                font-size: 1.35rem
                font-weight: 600
                // white-space: nowrap
                // display: -webkit-box
                // -webkit-box-orient: vertical
                // -webkit-line-clamp: 2
                overflow: hidden
                text-overflow: ellipsis
    @media screen and (max-width: 1200px)
        .wrapper
            .desc
                padding: 0.5rem
                text-align: center
                p, span
                    font-size: 1rem
                .name
                    font-size: 1rem
