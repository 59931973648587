.root
    // padding: 10px
    max-height: 80vh
    overflow: auto
    .buttonShow
        // border-radius: 30px !important
        // background-color: #8F4ED3 !important
        // color: #FFF
        // padding: 12px 24px !important
        width: fit-content
        height: fit-content
        min-width: 150px
        white-space: nowrap
        border-radius: 5px 0 0 5px
        // &:hover
        //     background-color: #b976ff !important
        &:disabled
            background: #F6F8FE !important
    .buttonReset
        // color: #8A8A8E
        // padding: 12px 24px !important
        width: fit-content
        height: fit-content
        min-width: 150px
        border-radius: 0 5px 5px 0
        // &:hover
        //     background: transparent !important
    .buttonControl
        padding: 0px 0px
        display: flex
        // flex-direction: column
        flex-wrap: nowrap
        @media screen and (max-width: 899px)
            display: flex
            flex-direction: row
            margin-bottom: 12px

.filter
    @media screen and (max-width: 899px)
        flex-direction: column !important

.company
    // background: #FFFFFF !important
    // box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1) !important
    // border-radius: 30px !important
    // padding: 32px !important
    position: relative
    .logo
        max-height: 80px
        width: 100%
        object-fit: cover
    .expandBtn
        cursor: pointer
        height: 70px
        background: linear-gradient(180deg, #FFFFFF 9.03%, rgba(245, 245, 245, 0) 93.07%)
        border-radius: 0 0 30px 30px
        position: absolute
        z-index: 1
        bottom: 0
        right: 32px
        left: 32px
        display: flex
        justify-content: center
        align-items: center
    .desc
        display: -webkit-box
        // -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
        overflow-wrap: break-word
