.root
  .head
    display: flex
    align-items: center
    justify-content: space-between
    .projectSelect
      width: 200px
  .table
    .id
      color: #ababab
      max-width: 100px
      min-width: 100px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name
      text-decoration: underline
    .country
      display: flex
    .actions
      display: flex
      justify-content: flex-end
      gap: 8px
      .button
        margin: 0
        &:hover
        i
          color: black
          margin: 0 !important
        button:disabled i
          color: initial
      .deleteBtn
        &:hover
          i
            color: white !important

.status
  width: fit-content
  text-align: center
  border-radius: 30px
  padding: 4px 12px
  font-size: 14px
  letter-spacing: 0.5px
  color: white
  @media screen and (max-width: 899px)
    width: fit-content !important

.buttonBack
  margin: -1rem 0 2rem

.title
  font-size: 24px !important
  margin: 1.5rem 0
