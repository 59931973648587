.uploadZone
    background: #F5F5F5
    border: 1px dashed #D9D9D9
    border-radius: 30px
    width: 100%
    height: 250px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    cursor: pointer
    margin-top: 1.5rem
    &:hover
        border: 1px dashed #000000
