.uploadZone
    background: #F4F5FA
    border-radius: 7px
    aspect-ratio: 1
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    cursor: pointer
    text-align: center
    padding: 20px
    // width: 111px
    // height: 111px
    & svg
        width: 48px
        height: 48px
        color: #8282824d
    p
        font-weight: 500
        font-size: 10px
        line-height: 13px
        text-align: center
        letter-spacing: 0.4px
        text-transform: uppercase
        color: #D2D3D6

    &:hover
        border: 1px dashed #000000
    .file
        display: flex
        align-items: center
        gap: 1rem
        @media screen and (max-width: 900px)
            flex-direction: column
            align-items: flex-start
            gap: 0
        .fileInfo
            display: flex
            align-items: center
            gap: 0.5rem
        .icon
            color: #872FC3
            width: 1.5rem !important
            height: 1.5rem !important
        .name
            cursor: pointer
            &:hover
                text-decoration: underline

.inputAvatar
    display: none

.image
    cursor: pointer
    // width: 111px
    // height: 111px
    // width: 100%
    // max-height: 250px
    // @media screen and (max-width: 900px)
    //     max-height: 250px
    //     max-width: 250px
    border-radius: 7px
    &:hover
        filter: brightness(1.2)
        border: 1px dashed #000000

.largePhoto
    width: 188px
    height: 188px
    p
        font-weight: 500
        font-size: 14px
        line-height: 19px
