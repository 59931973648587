.filters
  margin-bottom: 20px
  .input
    margin-bottom: 20px
    input
      border-radius: 4px
      border: 1px solid black
      display: inline-block
      width: 100%
  .select
    margin-bottom: 20px
  .button
    width: 100%
    white-space: nowrap
    margin-bottom: 20px
