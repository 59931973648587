.container
  .input
    background: #FFFFFF
    border: 1px solid #000000
    width: 100%
    padding: 11px 18px
    font-size: 18px
    outline: none
    display: block
    margin: 15px 0px 15px 0px
    &:focus
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5)
    &:placeholder
      color: #BDBDBD
  .error
    color: red
    font-size: 14px
