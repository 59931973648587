.info
    display: flex
    flex-direction: column
    gap: 4px
    .row
        display: flex
        gap: 8px
        .label
            color: #ABABAB

    .error
        color: red
.actions
    padding: 0 12px
    .action
        display: block
        margin-bottom: 15px
        width: 100%
