.card
    padding: 28px 20px
    position: relative
    .cardTitleBg
        position: absolute
        z-index: 0
        inset: 0
        height: 100px
    .cardTitleInfo
        width: calc(100% - 102px - 32px - 36px)
        p
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
            width: fit-content
            max-width: 100%
        *
            color: #fff
        .isReadyTalk
            *
                color: rgba(255, 255, 255, 0.87)
            * path
                color: rgba(255, 255, 255, 0.6)
    .avatar
        height: 102px
        width: 102px
        font-size: 1.75rem
        text-transform: uppercase
        cursor: pointer
        border: 7px solid #FFFFFF
        border-radius: 64px
        &:hover
            border-color: #d6d6d6
        // & path
        //     color: #FFF
    .editBtn
        position: absolute
        z-index: 2
        right: 16px
        top: 16px
        color: #FFF

.button
    display: flex
    justify-content: center
    .submitButton
        // border-radius: 36px
        font-size: 14px
        background-color: #000
        // padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        &:hover
            background: #5a5a5a
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 900px)
            width: 100%
    .secondaryButton
        // border-radius: 36px
        font-size: 14px
        background-color: #F6F8FE
        color: #000
        // padding: 1rem 5rem
        // width: fit-content
        width: 100%
        text-transform: none
        box-shadow: none
        &:hover
            background: #eeeeee
        &:disabled
            background: #F6F8FE
        @media screen and (max-width: 900px)
            width: 100%

.inputAvatar
    display: none

.cameraLabel
    position: absolute
    z-index: 99
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    right: 2%
    bottom: 2%
    border-radius: 50%
    background: #838383
    padding: 5px
    color: #fff
    opacity: 0.4
    &:hover
        opacity: 1
        background: #3e3e3e

.slot
    color: #872FC3
    border: 1px solid #872FC3
    border-radius: 30px
    padding: 4px 8px
    display: flex
    align-items: center

@media screen and (max-width: 899px)
    .card
        .cardTitleInfo
            width: 100%
            align-items: center
            *
                text-align: center
                color: rgba(58, 53, 65, 0.87)
