.form
  min-width: 440px
  .input
    margin-bottom: 15px
    input
      border: 1px solid black
      border-radius: 4px
      width: 100%
  .select, .date
    margin-bottom: 15px

@media screen and (max-width:899px)
  .form
    min-width: auto
