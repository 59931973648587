.labelContainer
  height: 51px
  display: flex
  align-items: center
  user-select: none
  cursor: pointer
  .input
    display: none
    & ~ .fakeInput
      width: 50px
      height: 50px
      border: 1px solid black
      border-radius: 4px
      position: relative
      margin-right: 15px
      &:focus
        box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5)
    &:checked ~ .fakeInput:before
      content: "\f633"
      font-family: bootstrap-icons
      position: absolute
      font-size: 23px
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      
