.document
    max-height: 200px
    max-width: 200px
    cursor: pointer
    object-fit: contain
    border: 1px solid gray
    border-radius: 4px
    &:hover
        filter: brightness(0.95)
        border: 1px dashed gray

.exampleFile
    position: relative
    width: fit-content
    .document
        max-height: 200px
        max-width: 200px
        cursor: pointer
        object-fit: contain
        border: 1px solid gray
        border-radius: 4px
    .iconDownload
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        display: none
    &:hover
        .document
            filter: brightness(0.95)
            border: 1px dashed gray
        .iconDownload
            display: flex
