.document
    max-height: 200px
    max-width: 200px
    cursor: pointer
    object-fit: contain
    &:hover
        filter: brightness(1.2)

// .button
//     display: flex
//     justify-content: center
//     .submitButton
//         border-radius: 36px
//         font-size: 15px
//         background-color: #000
//         padding: 1rem 5rem
//         width: fit-content
//         text-transform: none
//         &:hover
//             background: #5a5a5a
//         &:disabled
//             background: #F6F8FE
//             color: rgba(0, 0, 0, 0.26)
//         @media screen and (max-width: 900px)
//             width: 100%
//     .secondaryButton
//         border-radius: 36px
//         font-size: 15px
//         background-color: #F6F8FE
//         color: #000
//         padding: 1rem 5rem
//         width: fit-content
//         text-transform: none
//         box-shadow: none
//         &:hover
//             background: #eeeeee
//         &:disabled
//             background: #F6F8FE
//             color: rgba(0, 0, 0, 0.26)
//         @media screen and (max-width: 900px)
//             width: 100%
