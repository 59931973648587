.main
  display: flex
  justify-content: center
  align-items: center
  padding: 3rem
  height: 100%
  width: 100%
  .form
    display: flex
    flex-direction: column
    gap: 34px
    max-width: 400px
    width: 100%
    .input
      width: 100%
    .submit
      width: 100%
