.header
  padding-top: 44px
  width: 100%
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  gap: 16px
  .logo
    font-weight: bold
    font-size: 23px
    text-transform: uppercase
    color: #1E3240
    cursor: pointer
    text-decoration: none
    text-shadow: 1px 1px 1px rgba(255,255,255,0.5)
    &.dark
      color: #fff
    span
      font-size: 23px
      color: #003CFF
  .exit
    font-size: 14px
    line-height: 18px
    text-transform: uppercase
  .settings
    font-size: 14px
    line-height: 18px
    text-transform: uppercase
    border: 1px solid
    border-radius: 4px
    padding: 7px
    &:hover
      background: gray
      color: white
  .right
    display: flex
    flex-wrap: wrap
    gap: 16px
    @media screen and (max-width: 899px)
      gap: 8px
